<template>
  <div>
    <input
      ref="fileInput"
      class="d-none"
      type="file"
      @change="fileInputChange"
    >
    <div
      :class="{'border-drag': drag, 'border-primary': drag}"
      class="card primary mb-3"
      @click.stop.prevent="dropZoneClick"
      @dragover.prevent
      @dragenter.stop.prevent.capture="dropZoneDragEnter"
      @dragleave.stop.prevent.capture="dropZoneDragLeave"
      @drop.stop.prevent.capture="dropZoneDrop"
    >
      <div
        :class="{'drag': drag}"
        class="card-body"
      >
        <div>
          <div v-if="uploading">
            <b-progress
              :value="100"
              variant="primary"
              :animated="true"
            />
          </div>
          <div
            v-else-if="!image"
            class="text-center text-gray-4 py-3"
          >
            <i
              :class="{'text-primary': drag, 'drag-text': drag}"
              class="fal fa-upload fa-2x"
            />
            <small class="d-block mt-2">Drag and Drop oder hier klicken</small>
          </div>
          <div v-else-if="image">
            <img
              :src="image.downloadUrl"
              class="w-100 my-3"
            >
            <button
              class="btn btn-link text-danger p-0"
              @click.stop="deleteImage()"
            >
              <slot name="deleteImageText">
                <i class="fas fa-times" /> Logo entfernen
              </slot>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathers from '../api'
import axios from 'axios'
export default {
  name: 'ImageUpload',
  props: {
    image: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    drag: false,
    uploading: false
  }),
  methods: {
    async fileInputChange (event) {
      if (event.target.files[0]) {
        const file = event.target.files[0]
        await this.uploadFile(file)
        event.target.value = ''
      }
    },
    dropZoneClick (event) {
      this.$refs.fileInput.click()
    },
    dropZoneDragEnter (event) {
      this.drag = true
    },
    dropZoneDragLeave (event) {
      this.drag = false
    },
    async dropZoneDrop (event) {
      this.drag = false

      if (event.dataTransfer.items) {
        const file = event.dataTransfer.items[0].getAsFile()
        await this.uploadFile(file)
      }
    },
    async uploadFile (file) {
      try {
        this.uploading = true
        const image = await feathers.service('file').create({
          name: file.name,
          mimeType: file.type
        })
        await axios.put(image.uploadUrl, file, this.config)
        this.$emit('uploaded', image)
      } catch (error) {
        console.log(error)
        throw error
      } finally {
        this.uploading = false
      }
    },
    deleteImage () {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
.border-dotted {
  border-style: dashed !important;
  border-width: 2px !important;
}

.border-drag{
  border-style: dashed !important;
  border-width: 2px !important;
  margin-top: -2px;
  margin-bottom: -2px;
}

.drag-text{
  margin-top: 0;
  margin-bottom: -2px;
}

.drag * {
  pointer-events: none;
}
</style>
