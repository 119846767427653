<template>
  <div class="row">
    <div class="col-12 col-xl-8 col-xxl-6">
      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Beitrag
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-input
            v-model="news.title"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            placeholder="Titel"
            required
            label="Titel"
          />
          <basic-textarea
            v-model="news.teaser"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            :align-center="false"
            required
            placeholder="Teaser Text"
            name="Teaser Text"
            rows="3"
          >
            <template #label>
              Teaser Text <br>
              (eine Kurze Einführung zum Beitrag)
            </template>
          </basic-textarea>
          <basic-textarea
            v-model="news.content"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            :align-center="false"
            required
            placeholder="Beitrag"
            name="Beitrag"
            rows="8"
          >
            <template #label>
              Beitrag
            </template>
          </basic-textarea>
        </template>
      </ca-card>
      <ca-card class="mb-3">
        <template #header>
          <div class="row">
            <div class="col">
              <h4 class="mb-0">
                Einstellungen
              </h4>
            </div>
          </div>
        </template>
        <template #body>
          <basic-datepicker
            v-model="news.publishedAt"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            format="DD.MM.YYYY HH:mm"
            label="öffentlichen ab"
          />
          <hr>
          <basic-select
            v-model="news.visibleFor"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            required
            label="Sichtbarkeit"
            :options="[
              {label: 'Für alle Händler sichtbar', value: 'USER'},
              {label: 'Für berrechtigte Händler sichtbar', value: 'PLUS_USER'},
              {label: 'Nur für Admins sichtbar', value: 'ADMIN'},
              {label: 'Nur für bestimmte Händler sichtbar', value: 'WHITELIST'},
            ]"
          />
          <template
            v-if="news.visibleFor === 'WHITELIST'"
            class="findMe"
          >
            <basic-typeahead
              id="whitelist-search"
              v-model="userSearch"
              placeholder="Suche nach Nutzern anhand der Nummmer"
              :query="searchUserByTraderId"
              :input-formatter="userFormatter"
              :result-formatter="userFormatter"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              :class="w-75"
              @hit="selectedUser = $event"
            >
              <template
                slot="append"
              >
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="addSelectedUserToWhitelist"
                >
                  <i class="fas fa-plus" />
                </button>
              </template>
            </basic-typeahead>

            <div class="form-row justify-content-end">
              <div class="col-12 col-lg-8">
                <transition-group
                  name="user"
                  tag="ul"
                  class="list-group list-group-flush"
                >
                  <li
                    v-for="(user, userIndex) of news.whitelistUsers"
                    :key="user._id"
                    class="list-group-item"
                  >
                    <div
                      class="form-row align-items-center"
                    >
                      <div class="col">
                        {{ user.company }}
                      </div>
                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn btn-link p-0"
                          @click="removeUserFromWhitelist(userIndex)"
                        >
                          <i class="fas fa-trash text-danger" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </div>
            </div>
          </template>
        </template>
      </ca-card>
    </div>
    <div class="col-12 col-xl-4 col-xxl-3">
      <ca-card class="mb-3">
        <template #header>
          <h4 class="mb-0">
            Bild hochladen
          </h4>
        </template>
        <template #body>
          <image-upload
            :image="news.coverImage"
            @uploaded="setCoverImage"
            @delete="deleteCoverImage"
          >
            <template #deleteImageText>
              <i class="fas fa-times" /> Bild entfernen
            </template>
          </image-upload>
        </template>
      </ca-card>
      <!-- <ca-card class="mb-3">
          <template #header>
            <h4 class="mb-0">
              Kategorien
            </h4>
          </template>
          <template #body>
            <button class="btn btn-primary rounded-pill py-0">
              Alle
            </button>
            <button class="btn btn-link py-0">
              guter Kram
            </button>
            <div class="scroll-wrapper mt-3">
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
              <basic-checkbox label="Kategorie 1" />
            </div>
          </template>
        </ca-card> -->
      <!-- <ca-card class="mb-3">
          <template #header>
            <h4 class="mb-0">
              Schlagwörter
            </h4>
          </template>
          <template #body>
            <basic-input>
              <option>
                <span>Name</span>
              </option>
              <template #append>
                <button
                  class="btn btn-primary"
                  type="button"
                >
                  <i class="fas fa-plus" />
                </button>
              </template>
            </basic-input>

            <div class="tags-wrapper mt-3">
              <span class="badge-pill badge-secondary mr-2">test</span>
              <span class="badge-pill badge-secondary mr-2">test</span>
              <span class="badge-pill badge-secondary mr-2">test</span>
            </div>
          </template>
        </ca-card> -->
    </div>
  </div>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea'
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead'
import ImageUpload from '@/components/ImageUpload'
import CaCard from '@/components/Card'
export default {
  inject: ['$validator'],
  name: 'NewsForm',
  components: {
    CaCard,
    BasicInput,
    BasicSelect,
    BasicTextarea,
    BasicDatepicker,
    BasicTypeahead,
    ImageUpload
  },
  props: {
    news: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userSearch: '',
      selectedUser: null
    }
  },
  methods: {
    setCoverImage (image) {
      this.$set(this.news, 'coverImage', image)
      this.$set(this.news, 'coverImageId', image._id)
    },
    deleteCoverImage () {
      this.$delete(this.news, 'coverImage')
      this.$set(this.news, 'coverImageId', null)
    },
    searchUserByTraderId (traderId) {
      return this.$store.dispatch('users/find', {
        query: {
          $limit: 10,
          traderId
        }
      })
    },
    userFormatter (user) {
      return `${user.traderId} - ${user.company}`
    },
    addSelectedUserToWhitelist () {
      if (this.selectedUser) {
        if (!this.news.whitelistUserIds) this.$set(this.news, 'whitelistUserIds', [])
        if (!this.news.whitelistUsers) this.$set(this.news, 'whitelistUsers', [])

        if (!this.news.whitelistUserIds.includes(this.selectedUser._id)) {
          this.news.whitelistUserIds.push(this.selectedUser._id)
          this.news.whitelistUsers.push(this.selectedUser)
        }
        this.selectedUser = null
        this.userSearch = ''
      }
    },
    removeUserFromWhitelist (userIndex) {
      this.news.whitelistUserIds.splice(userIndex, 1)
      this.news.whitelistUsers.splice(userIndex, 1)
    }
  }
}
</script>
